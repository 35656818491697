document.addEventListener("alpine:init", () => {
  window.Alpine.data("headerCart", () => ({
    headerCartOpen: false,
    cartIsUpdated: false,

    init() {},

    toggleHeaderCart() {
      this.headerCartOpen = !this.headerCartOpen;
    },

    cartUpdated() {
      this.headerCartOpen = true;
      this.cartIsUpdated = true;

      setTimeout(() => {
        this.cartIsUpdated = false;
      }, 1800);
    },
  }));
});
