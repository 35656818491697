document.addEventListener("alpine:init", () => {
  window.Alpine.data("productPopup", () => ({
    productPopupOpen: false,
    productPopupTitle: "",
    productPopupOptions: [],
    productPopupOptionId: null,
    productPopupForm: null,

    closeProductPopup() {
      this.productPopupOpen = false;
      this.productPopupTitle = "";
      this.productPopupOptions = [];
    },

    openProductPopup(event) {
      this.productPopupOpen = true;
      this.productPopupTitle = event.detail.product?.name;
      this.productPopupOptions = event.detail.product?.options;
      this.productPopupOptionId = this.productPopupOptions[0]?.id;
    },

    submitProductPopup() {
      const formData = new FormData();
      formData.append(
        "quantity",
        this.$refs.productPopupForm.querySelector("[name='quantity']").value,
      );
      formData.append("product_id", this.productPopupOptionId);

      fetch("/?wc-ajax=add_to_cart", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            if (data.product_url) {
              window.location = data.product_url;
            }

            return;
          }

          for (let fragmentKey in data.fragments) {
            let fragment = document.querySelector(
              `[data-fragment="${fragmentKey}"]`,
            );

            if (fragment) {
              fragment.outerHTML = data.fragments[fragmentKey];
            }
          }

          this.$nextTick(() => this.$dispatch("cart-updated"));
        })
        .catch(() => null);

      this.closeProductPopup();
    },
  }));
});
