document.addEventListener("alpine:init", () => {
  window.Alpine.data("slider", () => ({
    skip: 4,
    atBeginning: false,
    atEnd: false,
    slider: null,
    sliderTrack: null,
    sliderItems: null,

    init() {
      this.slider = this.$el;
      this.sliderTrack = this.slider.querySelector("ul:first-child");
      this.sliderItems = this.sliderTrack.children;

      new ResizeObserver(() => this.determineSkip()).observe(this.slider);
    },

    determineSkip() {
      let itemWidth = this.sliderItems[0].getBoundingClientRect().width;
      let sliderWidth = this.slider.getBoundingClientRect().width;
      this.skip = Math.floor(sliderWidth / itemWidth);
    },

    next() {
      this.scrollTo((current, offset) => current + offset * this.skip);
    },

    previous() {
      this.scrollTo((current, offset) => current - offset * this.skip);
    },

    scrollTo(strategy) {
      let current = this.sliderTrack.scrollLeft;
      let offset =
        this.sliderTrack.firstElementChild.getBoundingClientRect().width;
      this.sliderTrack.scrollTo({
        left: strategy(current, offset),
        behavior: "smooth",
      });
    },

    slideBindings: {
      "x-intersect:enter.threshold.05"() {
        if (this.sliderItems.length === 1) {
          this.atBeginning = true;
          this.atEnd = true;
        } else if (this.sliderItems[0] === this.$el) {
          this.atBeginning = true;
        } else if (this.sliderItems[this.sliderItems.length - 1] === this.$el) {
          this.atEnd = true;
        }
      },
      "x-intersect:leave.threshold.05"() {
        if (this.sliderItems.length === 1) {
          this.atBeginning = true;
          this.atEnd = true;
        } else if (this.sliderItems[0] === this.$el) {
          this.atBeginning = false;
        } else if (this.sliderItems[this.sliderItems.length - 1] === this.$el) {
          this.atEnd = false;
        }
      },
    },
  }));
});
