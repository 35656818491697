document.addEventListener("alpine:init", () => {
  window.Alpine.data("product", () => ({
    showBottomBar: false,

    init() {},

    scrollToProductActions(event) {
      event.preventDefault();

      this.$refs.productActions.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  }));
});
