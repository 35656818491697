document.addEventListener("alpine:init", () => {
  window.Alpine.data("cart", () => ({
    init() {
      this.bottomBar = this.$refs.bottomBar;

      document
        .querySelectorAll(
          '.woocommerce-cart-form [x-data="quantityInput"] select',
        )
        .forEach((select) => {
          select.addEventListener("change", this.updateCart);
        });

      document
        .querySelectorAll(
          '.woocommerce-cart-form [x-data="quantityInput"] input',
        )
        .forEach((input) => {
          input.addEventListener("blur", this.updateCart);
        });

      // YITH Points and Rewards fix
      if (
        document.querySelector(
          "#yith-par-message-reward-cart #ywpar-points-max",
        )
      ) {
        let pointsMaxInput = document.querySelector(
          "#yith-par-message-reward-cart #ywpar-points-max",
        );
        pointsMaxInput.type = "number";
        pointsMaxInput.max = pointsMaxInput.value;

        let pointsCheckInput = document.querySelector(
          "#yith-par-message-reward-cart #ywpar_input_points_check",
        );
        if (pointsCheckInput) {
          pointsCheckInput.value = 1;
        }
      }
    },

    updateCart() {
      let button = document.querySelector('button[name="update_cart"]');

      if (!button) return;

      button.removeAttribute("disabled");
      button.click();
    },

    showBottomBar() {
      this.bottomBar.classList.remove("hidden");
    },

    hideBottomBar() {
      this.bottomBar.classList.add("hidden");
    },
  }));
});
