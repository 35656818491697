import { searchClient } from "@algolia/client-search";
import Alpine from "alpinejs";

document.addEventListener("alpine:init", () => {
  window.Alpine.data("search", () => ({
    searchQuery: "",
    searchSelectedFacets: {},
    searchConfig: {},
    searchClient: null,
    searchLoading: false,

    init() {
      this.searchConfig = window.searchConfig;
      this.searchClient = searchClient(
        this.searchConfig.appId,
        this.searchConfig.key,
      );

      const urlParams = new URLSearchParams(window.location.search);
      const searchQuery = urlParams.get("s");
      if (searchQuery) {
        this.searchQuery = searchQuery;
        this.search(true);
      }

      window.addEventListener("popstate", () => {
        const urlParams = new URLSearchParams(window.location.search);
        const searchQuery = urlParams.get("s");
        if (searchQuery && searchQuery !== this.searchQuery) {
          this.searchQuery = searchQuery;
        }
      });

      this.$watch("searchQuery", () => this.search(false, true));
    },

    search(onPageLoad = false, reloadFacets = false) {
      if (this.searchQuery.length < 2) {
        return;
      }

      if (reloadFacets) {
        this.searchSelectedFacets = {};
      }

      this.searchLoading = true;

      let searchUrl = `/?s=${this.searchQuery}`;
      window.history.pushState({ path: searchUrl }, "", searchUrl);

      if (!onPageLoad) {
        this.changeMetaTitle();
      }

      this.searchClient
        .searchSingleIndex({
          indexName: this.searchConfig.index,
          searchParams: {
            query: this.searchQuery,
            facets: ["*"],
            hitsPerPage: 100,
            facetFilters: Object.entries(this.searchSelectedFacets)
              .map(([facet, values]) =>
                values.map((value) => `${facet}:${value}`),
              )
              .join("AND"),
          },
        })
        .then((response) => {
          Alpine.store("search", {
            results: response.hits,
            total: response.nbHits,
            facets: response.facets,
            selectedFacets: this.searchSelectedFacets,
            query: this.searchQuery,
          });

          let container = this.searchConfig.containerTemplate;
          let main = document.querySelector("main");
          main.outerHTML = container;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.searchLoading = false;
        });
    },

    changeMetaTitle() {
      let title = document.querySelector("title");
      title.innerText = this.searchConfig.metaTitleTemplate.replace(
        "%s",
        this.searchQuery,
      );
    },

    setSearchFilter(event) {
      let facetName = event.detail.facet;
      let facetValue = event.detail.value;

      if (this.searchSelectedFacets[facetName]) {
        if (this.searchSelectedFacets[facetName].includes(facetValue)) {
          this.searchSelectedFacets[facetName] = this.searchSelectedFacets[
            facetName
          ].filter((value) => value !== facetValue);
        } else {
          this.searchSelectedFacets[facetName].push(facetValue);
        }
      } else {
        this.searchSelectedFacets[facetName] = [facetValue];
      }

      this.search();
    },
  }));
});
