document.addEventListener("alpine:init", () => {
  window.Alpine.data("quantityInput", () => ({
    init() {},

    selectOnChange(event) {
      event.preventDefault();

      if (parseInt(event.target.value) === 11) {
        let input = event.target
          .closest("[x-data='quantityInput']")
          .querySelector("input[type='number']");

        input.value = "11";
        input.parentElement.classList.remove("hidden");
        input.parentElement.classList.add("flex");
        input.setAttribute("name", event.target.getAttribute("name"));

        event.target.remove();
      }
    },

    increment(event) {
      event.preventDefault();

      let input = event.target
        .closest("[x-data='quantityInput']")
        .querySelector("input[type='number']");
      let max = parseInt(input.getAttribute("max"));
      let value = parseInt(input.value);

      if (isNaN(max) || value < max) {
        input.value = value + 1;
        input.dispatchEvent(new Event("input", { bubbles: true }));
      }
    },

    decrement(event) {
      event.preventDefault();

      let input = event.target
        .closest("[x-data='quantityInput']")
        .querySelector("input[type='number']");
      let min = parseInt(input.getAttribute("min"));
      let value = parseInt(input.value);

      if (value > min) {
        input.value = value - 1;
        input.dispatchEvent(new Event("input", { bubbles: true }));
      }
    },
  }));
});
