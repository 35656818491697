document.addEventListener("alpine:init", () => {
  window.Alpine.data("addToCart", () => ({
    state: null,

    init() {},

    submit(event) {
      event.preventDefault();

      let form = this.$el.closest("form");
      let variationIdInput = form.querySelector('input[name="variation_id"]');

      if (variationIdInput && Number(variationIdInput.value) === 0) {
        /* global wc_add_to_cart_variation_params */
        alert(wc_add_to_cart_variation_params.i18n_make_a_selection_text);

        return;
      }

      if (
        document.body.classList.contains("woocommerce-cart") ||
        (variationIdInput && Number(variationIdInput.value) > 0)
      ) {
        form.submit();
        return;
      }

      let quantityWrapper = form.querySelector("[x-data='quantityInput']");
      let quantityInput = quantityWrapper.querySelector(
        "select[name], input[name]",
      );
      let quantity = quantityInput.value;

      const formData = new FormData();
      formData.append("quantity", quantity);

      for (let key in this.$el.dataset) {
        formData.append(
          key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`),
          this.$el.dataset[key],
        );
      }

      this.state = "loading";
      fetch("/?wc-ajax=add_to_cart", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error && data.product_url) {
            window.location = data.product_url;
            return;
          }

          for (let fragmentKey in data.fragments) {
            let fragment = document.querySelector(
              `[data-fragment="${fragmentKey}"]`,
            );

            if (fragment) {
              fragment.outerHTML = data.fragments[fragmentKey];
            }
          }

          this.state = "success";
          this.$nextTick(() => this.$dispatch("cart-updated"));

          setTimeout(() => {
            this.state = null;
          }, 2000);
        })
        .catch(() => {
          this.state = null;
        });
    },
  }));
});
