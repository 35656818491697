document.addEventListener("alpine:init", () => {
  window.Alpine.data("productCategory", () => ({
    filterSidebarOpen: false,

    init() {
      window.addEventListener("load", function () {
        document
          .querySelectorAll("select[name='orderby']")
          .forEach((select) => {
            select.addEventListener("change", function () {
              this.closest("form").submit();
            });
          });
      });
    },

    toggleFilterSidebar() {
      this.filterSidebarOpen = !this.filterSidebarOpen;

      if (!this.filterSidebarOpen) {
        let scrollY = document.body.style.marginTop;

        document.body.style.marginTop = null;
        document.body.classList.remove("overflow-y-scroll", "fixed", "w-full");

        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      } else {
        document.body.style.marginTop = `-${window.scrollY}px`;
        document.body.classList.add("overflow-y-scroll", "fixed", "w-full");
      }
    },
  }));
});
